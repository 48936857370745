import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength, minLength, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'invite-login',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        token: this.$route.query.token,
        email: this.$route.query.email
      },
      message: {
        title: '',
        text: ''
      },
      validationErrors: {},
      isPasswordShow: false,
      isPasswordConfirmationShow: false,
      isLoginLoading: false,
      isShowRestoreMessage: false,
    }
  },
  components: {},
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  created() {
    if (this.payload.token !== '' && this.payload.email !== '') {
      this.login();
    } else {
      this.message = {
        title: this.$t('failInviteAuthTitle'),
        text: this.$t('failInviteQueryText')
      }
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isInvitePasswordRestoreLoading: 'auth/isInvitePasswordRestoreLoading',
    }),

    isDisabled() {
      return this.payload.password === '' || this.payload.password_confirmation === '';
    },

    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin', {count: 6}));
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax', {count: 20}));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      inviteLogin: 'auth/AUTH_REQUEST_INVITE_LOGIN',
      fetchUser: 'profile/PROFILE_REQUEST_USER_DATA_GET',
      setPassword: 'auth/AUTH_REQUEST_INVITE_PASSWORD_SET',
    }),
    login() {
      this.isLoginLoading = true;
      this.inviteLogin({
        email: this.payload.email,
        token: this.payload.token,
      }).then(() => {
          this.fetchUser().then(() => {
            this.message = {
              title: this.$t('successInviteAuthTitle'),
              text: this.$t('successInviteAuthText')
            }
          }).catch(error => {
            if (error.response.status === 422) {
              this.$toasted.error(error.response.data.message);
            }
            this.message = {
              title: this.$t('failInviteAuthTitle'),
              text: this.$t('failInviteAuthText')
            }
          }).finally(()=>{
            this.isLoginLoading = false;
          });
        }).catch(error => {
          if (error.response.status === 403) {
            this.message = {
              title: error.response.data.message,
              text: this.$t('failInviteAuthText')
            }
          } if (error.response.data.error.http_code === 403) {
            this.message = {
              title: error.response.data.message,
              text: this.$t('failInviteAuthText')
            }
          }else {
            this.message = {
              title: this.$t('failInviteAuthTitle'),
              text: this.$t('failInviteAuthText')
            }
          }
          this.isLoginLoading = false;
        });
    },
    resetForm() {
      this.$v.$reset();
      this.payload.password = '';
      this.payload.password_confirmation = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setPassword(this.payload)
            .then(() => {
              this.isShowRestoreMessage = true;
              this.resetForm();
            }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
